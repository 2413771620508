import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import LeopardGridViewConfiguration from '../components/LeopardGridViewConfiguration';
import LeopardChartConfiguration from '../components/LeopardChartConfiguration';
import LeopardTimelineConfiguration from '../components/LeopardTimelineConfiguration';
import LDH from '../helpers/LeopardDataHelper';
import {connect} from 'react-redux';
import $ from 'jquery';
import LeopardStaticUIConfig from '../foundation/LeopardStaticUIConfig';
import LeopardAjaxHelper from '../helpers/LeopardAjaxHelper';
import LeopardReportConfiguration from "../components/LeopardReportConfiguration";
import LeopardMapConfiguration from "../components/LeopardMapConfiguration";
import LeopardDocumentEditorConfiguration from "../components/LeopardDocumentEditorConfiguration";
import LeopardAnalyticsDashboardConfiguration from "../components/LeopardAnalyticsDashboardConfiguration";
import LeopardRegexTesterConfiguration from "../components/LeopardRegexTesterConfiguration";
import LeopardAPIGatewayConfig from "../foundation/LeopardAPIGatewayConfig";
import LeopardFormEditorConfiguration from "../components/LeopardFormEditorConfiguration";
import LeopardTabbedViewConfiguration from "../components/LeopardTabbedViewConfiguration";
import LeopardChildGridView from "../foundation/LeopardChildGridView";
import LeopardHtmlFormBuilderConfiguration from "../components/LeopardHtmlFormBuilderConfiguration";
import LeopardDiagramConfiguration from "../components/LeopardDiagramConfiguration";

class LeopardSingleDataView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columnNames: null,
            gridViewName: null,
            mapId: null,
            chartDefinition: null,
            timelineDefinition: null,
            tabbedViewDefinition: null,
            gridViewDefinition: null,
            reportDefinition: null,
            documentDefinition: null,
            analyticsDefinition: null,
            formDefinition: null,
            diagramDefinition: null,
            mapDefinition: null,
            styleDefinition: null,
            dataInitializedOnControls: false,
            dataViewLayouts: [],
            staticJSONData: [],
            gridViewDataSourceUrl: null,
            dataViewConfigVersion: 0
        }
    }

    updateWindowDimensionsForChildView = () => {
        LRH.TriggerWindowResizeEvent();
    };

    loadGridViewDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let dataViewNote = data.dataViewNote;
            let limitedColumns = [];

            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            for (let i = 0; i < dataViewNote.gridViewDefinition.columnDefinition.length; i++) {
                dataViewNote.gridViewDefinition.columnDefinition[i].hasSorted = false;
                if (!LDH.IsValueEmpty(dataViewNote.gridViewDefinition.columnDefinition[i].isEnabled) &&
                    dataViewNote.gridViewDefinition.columnDefinition[i].isEnabled === false) {
                    limitedColumns.push(dataViewNote.gridViewDefinition.columnDefinition[i].columnName);
                }
            }

            let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
            LRH.ApplyCustomStyle(dataViewNote.styleDefinition, version);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            let persistentId = dataViewNote.gridViewDefinition.dataViewPersistentId;
            LeopardAjaxHelper.GetDataViewLayoutById(userId, persistentId, function (response) {
                that.setState({
                    gridViewDefinition: dataViewNote.gridViewDefinition,
                    styleDefinition: dataViewNote.styleDefinition,
                    dataViewLayouts: response,
                    gridViewDataSourceUrl: data.dataSourceUrl,
                    dataViewConfigVersion: data.dataViewConfigVersion
                }, function () {
                    if (!LDH.IsObjectNull(callback)) callback();
                });
            }, function () {
                that.setState({
                    gridViewDefinition: dataViewNote.gridViewDefinition,
                    styleDefinition: dataViewNote.styleDefinition,
                    dataViewLayouts: []
                }, function () {
                    if (!LDH.IsObjectNull(callback)) callback();
                });
            });

            let tableName = data.dataSourceUrl;
            if (LDH.IsObjectNull(dataViewNote.gridViewDefinition.gridViewEngine) ||
                dataViewNote.gridViewDefinition.gridViewEngine === "odata-engine") {

                LeopardAjaxHelper.GetDataTableColumnsByDataSourceUrl(tableName, limitedColumns, function (columns) {
                        that.setState({columnNames: columns, gridViewName: data.dataViewName}, function () {
                            if (!LDH.IsObjectNull(callback)) callback();
                        });
                    }, function (error, sessionTimeout) {
                        if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                            error.message.indexOf("Cannot read property '") !== -1 &&
                            error.message.indexOf("' of undefined") !== -1) {
                            LRH.ShowToast("Your Data View might have been deleted or moved. " +
                                "Please refresh this page to update the Control Centre.", "error", 5000);
                        } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve data for your Data View. " +
                                "Please check your network connection status and try again.", "error", 5000);
                            localStorage.setItem("LoadFirstMenuItem", "false");
                        }
                        LRH.ShowOrHideMenuLoadingProgress(false);
                        LRH.ShowUnhandledExceptionForMasterContent();
                    }, data.dataViewNote.gridViewDefinition.dataSourceCustomQueryTableSchema,
                    data.dataViewNote.gridViewDefinition.customComputedColumns,
                    data.dataViewNote.gridViewDefinition.dataSourceOverrideMeta);
            } else if (dataViewNote.gridViewDefinition.gridViewEngine === "json-engine") {
                let postTemplate = LDH.DeepClone(LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate());
                postTemplate.source = LDH.GenerateGuid();
                postTemplate.type = dataViewNote.gridViewDefinition.dataSourceRequestType;

                let userProfile = window.userProfile;
                let userId = LDH.GetUserIdFromUserProfile(userProfile);
                let postData = [{"owner": userId}];

                if (postTemplate.type === "leopardsystems.document.list") {
                    postData = "not-required";
                }

                LeopardAjaxHelper.SendRequestByEventSync(function (response) {
                    let responseData = response.body.data;
                    if (!LDH.IsObjectNull(responseData) && responseData.length > 0) {
                        let columnFields = Object.keys(responseData[0]);
                        if (!LDH.IsValueEmpty(dataViewNote.gridViewDefinition.columnDefinitionForDynamicDS)) {
                            columnFields = dataViewNote.gridViewDefinition.columnDefinitionForDynamicDS.split(',');
                        }
                        let customComputedColumns = data.dataViewNote.gridViewDefinition.customComputedColumns;
                        if (!LDH.IsObjectNull(customComputedColumns) && customComputedColumns.length > 0) {
                            for (let k = 0; k < customComputedColumns.length; k++) {
                                columnFields.push(customComputedColumns[k].name);
                            }
                        }
                        let includedColumns = LDH.GetIncludedColumns(limitedColumns, columnFields, true);
                        that.setState({
                            columnNames: {fullColumns: columnFields, limitedColumns: includedColumns},
                            gridViewName: data.dataViewName,
                            staticJSONData: responseData
                        }, function () {
                            if (!LDH.IsObjectNull(callback)) callback();
                        });
                    } else {
                        let columnFields = [];
                        if (!LDH.IsValueEmpty(dataViewNote.gridViewDefinition.columnDefinitionForDynamicDS)) {
                            columnFields = dataViewNote.gridViewDefinition.columnDefinitionForDynamicDS.split(',');
                        }
                        let customComputedColumns = data.dataViewNote.gridViewDefinition.customComputedColumns;
                        if (!LDH.IsObjectNull(customComputedColumns) && customComputedColumns.length > 0) {
                            for (let k = 0; k < customComputedColumns.length; k++) {
                                columnFields.push(customComputedColumns[k].name);
                            }
                        }
                        let includedColumns = LDH.GetIncludedColumns(limitedColumns, columnFields, true);
                        that.setState({
                            columnNames: {fullColumns: columnFields, limitedColumns: includedColumns},
                            gridViewName: data.dataViewName,
                            staticJSONData: []
                        }, function () {
                            if (!LDH.IsObjectNull(callback)) callback();
                        });
                    }
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to retrieve document content.", "error", 5000);
                    }
                }, postTemplate, postData);
            } else if (dataViewNote.gridViewDefinition.gridViewEngine === "csv-adhoc-engine") {
                let columnFields = [];
                if (!LDH.IsValueEmpty(dataViewNote.gridViewDefinition.columnDefinitionForDynamicDS)) {
                    columnFields = dataViewNote.gridViewDefinition.columnDefinitionForDynamicDS.split(',');
                }
                let customComputedColumns = data.dataViewNote.gridViewDefinition.customComputedColumns;
                if (!LDH.IsObjectNull(customComputedColumns) && customComputedColumns.length > 0) {
                    for (let k = 0; k < customComputedColumns.length; k++) {
                        columnFields.push(customComputedColumns[k].name);
                    }
                }
                let includedColumns = LDH.GetIncludedColumns(limitedColumns, columnFields, true);
                that.setState({
                    columnNames: {fullColumns: columnFields, limitedColumns: includedColumns},
                    gridViewName: data.dataViewName
                }, function () {
                    if (!LDH.IsObjectNull(callback)) callback();
                });
            }
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadAnalyticsDashboardDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({analyticsDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadReportDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({reportDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadDocumentEditorDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({documentDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadMapDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({mapDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadFormDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({formDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadDiagramDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({diagramDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadHtmlFormBuilderDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({formDefinition: data.dataViewNote}, function () {
                if (!LDH.IsObjectNull(callback)) callback();
            });
        }, function (error, sessionTimeout) {
            if (!LDH.IsObjectNull(error) && !LDH.IsObjectNull(error.message) && !LDH.IsValueEmpty(error.message) &&
                error.message.indexOf("Cannot read property '") !== -1 &&
                error.message.indexOf("' of undefined") !== -1) {
                LRH.ShowToast("Your Data View might have been deleted or moved. " +
                    "Please refresh this page to update the Control Centre.", "error", 5000);
            } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve data for your Data View. " +
                    "Please check your network connection status and try again.", "error", 5000);
            }
            LRH.ShowOrHideMenuLoadingProgress(false);
            LRH.ShowUnhandledExceptionForMasterContent();
        });
    };

    loadChartDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            let dataViewNote = data.dataViewNote;
            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({chartDefinition: dataViewNote});
            if (!LDH.IsObjectNull(callback)) callback();
        });
    };

    loadTimelineDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            let dataViewNote = data.dataViewNote;
            if (!that.props.isLoadForTabbedView) {
                window.cachedSingleDataViewConfig = LDH.DeepClone(data);
            }

            that.setState({timelineDefinition: dataViewNote});
            if (!LDH.IsObjectNull(callback)) callback();
        });
    };

    loadTabbedViewDefinitionByAjaxRequest = (callback) => {
        let that = this;
        let dataViewId = this.props.dataViewId;
        let userProfile = window.userProfile;
        let userId = LDH.GetUserIdFromUserProfile(userProfile);

        LeopardAjaxHelper.GetDataViewById(userId, dataViewId, function (data) {
            let settingsVersion = data.settingsVersion;
            if (LDH.IsObjectNull(settingsVersion)) settingsVersion = 0;
            $(".dataview-settings-version").attr("settingsversion", settingsVersion);

            let dataViewNote = data.dataViewNote;
            window.cachedSingleDataViewConfig = LDH.DeepClone(data);

            that.setState({tabbedViewDefinition: dataViewNote});
            if (!LDH.IsObjectNull(callback)) callback();
        });
    };

    updateGridViewDefinitionOnSingleDataView = (definition) => {
        this.setState({gridViewDefinition: definition});
    };

    dataInitializedOnControlsUpdateRequest = () => {
        this.setState({dataInitializedOnControls: true});
    };

    componentDidMount = () => {
        let that = this;
        if (!that.props.isLoadForTabbedView) {
            LeopardStaticUIConfig.Global_DashboardDataViewListeners = [];
            LeopardStaticUIConfig.Global_DashboardDataViewSiteAccessRegister = [];
        }

        if (this.props.dataViewType === "datagrid") {
            window.Global_TempDateConvertion = [];
            this.loadGridViewDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "chart") {
            this.loadChartDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "timeline") {
            this.loadTimelineDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "tabbed-view") {
            this.loadTabbedViewDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "diagram") {
            this.loadDiagramDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "report") {
            this.loadReportDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "aws-quicksight-dashboard") {
            this.loadAnalyticsDashboardDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "document-editor") {
            this.loadDocumentEditorDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "map") {
            this.loadMapDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "form-editor") {
            this.loadFormDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }

        if (this.props.dataViewType === "html-form-builder") {
            this.loadHtmlFormBuilderDefinitionByAjaxRequest(function () {
                that.props.updateWindowDimensionsRequired();
                LRH.ShowOrHideMenuLoadingProgress(false);
            });
        }
    };

    componentWillUnmount = () => {
        window.cachedSingleDataViewConfig = null;
    };

    chartDefinitionUpdateForPreview = (data) => {
        let definitionClone = LDH.DeepClone(this.state.chartDefinition);
        definitionClone.chartDefinition = data.definition;

        this.setState({chartDefinition: definitionClone});
    };

    renderLoadingProgressIndicator = () => {
        LRH.ShowOrHideMenuLoadingProgress(true);
        return (
            <React.Fragment>
                <div style={{height: "100%", width: "100%", display: "flex"}}>
                    <span className={"leopard-loading-icon"} style={{margin: "auto"}}>
                        <i className="leopard-largeloading-progress-icon fas fa-spinner fa-pulse"></i>
                        <span className="leopard-largeloading-progress-text">Loading data...</span>
                    </span>
                </div>
            </React.Fragment>
        );
    };

    requireReloadDataView = (e) => {

    };

    setGridViewInstanceForChildView = (instanceData) => {
        this.props.setGridViewInstanceForChildView(instanceData);
    };

    render() {
        if (this.props.dataViewType === "datagrid") {
            if (LDH.IsObjectNull(this.state.columnNames) || LDH.IsObjectNull(this.state.columnNames.fullColumns) ||
                LDH.IsObjectNull(this.props.dataViewId) || LDH.IsObjectNull(this.state.gridViewName) ||
                LDH.IsObjectNull(this.state.gridViewDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            let gridViewDefinition = this.state.gridViewDefinition;
            let styleDefinition = this.state.styleDefinition;
            let displayAttr = this.state.dataInitializedOnControls ? "block" : "none";
            let dataSourceId = this.props.dataSourceUrl;

            if (!LDH.IsValueEmpty(dataSourceId) && !LDH.IsValueEmpty(this.state.gridViewDataSourceUrl) &&
                dataSourceId.replace("?tableName=", "") !== this.state.gridViewDataSourceUrl &&
                !this.props.isLoadForTabbedView && this.state.gridViewDataSourceUrl.toString() !== "0") {
                dataSourceId = this.state.gridViewDataSourceUrl;
            }

            if (this.props.isLoadForTabbedView) {
                dataSourceId = this.state.gridViewDataSourceUrl;
            }

            let dataViewLayouts = this.state.dataViewLayouts;

            if (!LDH.IsObjectNull(gridViewDefinition.overrideDatabaseTableField) &&
                !LDH.IsValueEmpty(gridViewDefinition.overrideDatabaseTableField)) {
                dataSourceId = gridViewDefinition.overrideDatabaseTableField;
            }

            if (!LDH.IsObjectNull(gridViewDefinition.extraQueryForSingleDataView) &&
                !LDH.IsValueEmpty(gridViewDefinition.extraQueryForSingleDataView) &&
                !this.props.isLoadForTabbedView) {
                dataSourceId += gridViewDefinition.extraQueryForSingleDataView;
            }

            let isGridViewJSONEngine = true;
            if (LDH.IsObjectNull(gridViewDefinition.gridViewEngine) ||
                gridViewDefinition.gridViewEngine === "odata-engine") {
                isGridViewJSONEngine = false;
            }

            if (this.props.isLoadForTabbedView) {
                return (
                    <LeopardChildGridView
                        gridViewId={this.props.dataViewId}
                        gridDefinition={gridViewDefinition}
                        gridViewName={this.state.gridViewName}
                        gridViewHeight={"auto"}
                        dashboardLevel={""}
                        viewOptionsText={""}
                        setGridViewInstance={this.props.setGridViewInstanceForChildView}
                        dataSourceUrl={dataSourceId}
                        isLoadForTabbedView={this.props.isLoadForTabbedView}
                        changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                        relationships={this.props.relationships}
                        isDataView={false}
                        columnFieldList={this.state.columnNames}
                        isGridViewJSONEngine={isGridViewJSONEngine}
                        staticJSONData={this.state.staticJSONData}
                        dashboardId={this.props.dataViewId}
                        dashboardLayoutDataViewMapping={null}
                        dataViewConfigVersion={this.state.dataViewConfigVersion}
                        gridViewDataSourceUrl={this.state.gridViewDataSourceUrl}
                        updateWindowDimensionsRequired={this.updateWindowDimensionsForChildView}
                        requireReloadDataView={(e) => this.requireReloadDataView(e)}
                    />);
            }
            if (displayAttr !== "none") LRH.ShowOrHideMenuLoadingProgress(false);

            return (
                <React.Fragment>
                    {displayAttr === "none" ? this.renderLoadingProgressIndicator() : ""}
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{display: displayAttr, backgroundColor: "white", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardGridViewConfiguration
                            dataSourceUrl={dataSourceId}
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            viewOptionsText={this.state.gridViewName}
                            columnFieldList={this.state.columnNames}
                            gridViewName={this.state.gridViewName}
                            windowHeight={this.props.windowHeight}
                            setGridViewInstance={this.props.setGridViewInstance}
                            dashboardLayoutDataViewMapping={[]}
                            isGridViewJSONEngine={isGridViewJSONEngine}
                            staticJSONData={this.state.staticJSONData}
                            dataViewConfigVersion={this.state.dataViewConfigVersion}
                            gridViewDataSourceUrl={this.state.gridViewDataSourceUrl}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            updateGridViewDefinitionOnSingleDataView={(e) => this.updateGridViewDefinitionOnSingleDataView(e)}
                            gridViewDefinition={gridViewDefinition} dashboardId={""}
                            styleDefinition={styleDefinition} dataViewLayouts={dataViewLayouts}
                            gridViewId={this.props.dataViewId}/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "report") {
            if (LDH.IsObjectNull(this.props.dataViewId) ||
                LDH.IsObjectNull(this.state.reportDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardReportConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            dataViewId={this.props.dataViewId}
                            reportDefinition={this.state.reportDefinition}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "aws-quicksight-dashboard") {
            if (LDH.IsObjectNull(this.props.dataViewId) ||
                LDH.IsObjectNull(this.state.analyticsDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardAnalyticsDashboardConfiguration
                            dataViewId={this.props.dataViewId}
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            analyticsDefinition={this.state.analyticsDefinition}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "document-editor") {
            if (LDH.IsObjectNull(this.props.dataViewId) ||
                LDH.IsObjectNull(this.state.documentDefinition)) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardDocumentEditorConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            viewType={"dataview"} dataViewId={this.props.dataViewId} isDataView={true}
                            documentDefinition={this.state.documentDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "photo") {
            if (this.props.dataViewId === null || this.state.photoDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "15%", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div className={"leopard-empty-dataview-warning"}>
                            This Data View cannot be used on its own. It must be linked with other Data Views in a
                            Workspace.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "notepad") {
            if (this.props.dataViewId === null || this.state.notepadDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div style={{textAlign: "center", marginTop: "15%", fontFamily: "Arial", fontSize: "20px"}}>
                        <i className="far fa-meh" style={{color: "#FF8200"}}></i>
                        <div className={"leopard-empty-dataview-warning"}>
                            This Data View cannot be used on its own. It must be linked with other Data Views in a
                            Workspace.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "form-editor") {
            if (this.props.dataViewId === null || this.state.formDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardFormEditorConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            dataViewId={this.props.dataViewId} isDataView={true}
                            formDefinition={this.state.formDefinition.formDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "html-form-builder") {
            if (this.props.dataViewId === null || this.state.formDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardHtmlFormBuilderConfiguration
                            dataViewId={this.props.dataViewId} isDataView={true}
                            formDefinition={this.state.formDefinition.formDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "regex-tester") {
            if (this.props.dataViewId === null || this.state.photoDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardRegexTesterConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            dataViewId={this.props.dataViewId} isDataView={true}
                            regexDefinition={this.state.regexDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "map") {
            if (this.props.dataViewId === null || this.state.mapDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardMapConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            viewType={"dataview"} dataViewId={this.props.dataViewId} isDataView={true}
                            mapDefinition={this.state.mapDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "chart") {
            if (this.props.dataViewId === undefined || this.props.dataViewId === null ||
                this.state.chartDefinition === undefined || this.state.chartDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }

            let hasChartDataReceived = false;
            if (!LDH.IsObjectNull(this.props.state.chartState)) {
                for (let i = 0; i < this.props.state.chartState.length; i++) {
                    if (this.props.state.chartState[i].dataViewId === this.props.dataViewId &&
                        !LDH.IsObjectNull(this.props.state.chartState[i].chartData) &&
                        Array.isArray(this.props.state.chartState[i].chartData)) {
                        hasChartDataReceived = true;
                    }
                }
            }
            let displayAttr = this.state.dataInitializedOnControls && hasChartDataReceived ? "block" : "none";
            if (displayAttr !== "none") LRH.ShowOrHideMenuLoadingProgress(false);

            return (
                <React.Fragment>
                    {displayAttr === "none" ? this.renderLoadingProgressIndicator() : ""}
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{display: displayAttr, backgroundColor: "white", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardChartConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            dataSourceUrl={this.props.dataSourceUrl}
                            palette={['#c3a2cc', '#b7b5e0', '#e48cba']}
                            customizeTextYAxis={"#value#"}
                            definition={this.state.chartDefinition}
                            dataViewId={this.props.dataViewId}
                            chartDefinitionUpdateForPreview={(e) => this.chartDefinitionUpdateForPreview(e)}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            setChartInstance={this.props.setChartInstance}
                            chartType={this.state.chartDefinition.chartType}/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "timeline") {
            if (this.props.dataViewId === undefined || this.props.dataViewId === null ||
                this.state.timelineDefinition === undefined || this.state.timelineDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }

            let hasTimelineDataReceived = false;
            if (!LDH.IsObjectNull(this.props.state.timelineState)) {
                for (let i = 0; i < this.props.state.timelineState.length; i++) {
                    if (this.props.state.timelineState[i].dataViewId === this.props.dataViewId &&
                        !LDH.IsObjectNull(this.props.state.timelineState[i].timelineData) &&
                        this.props.state.timelineState[i].timelineData.groupingData !== undefined &&
                        this.props.state.timelineState[i].timelineData.resourcesData !== undefined) {
                        hasTimelineDataReceived = true;
                    }
                }
            }
            let displayAttr = this.state.dataInitializedOnControls && hasTimelineDataReceived ? "block" : "none";
            if (displayAttr !== "none") LRH.ShowOrHideMenuLoadingProgress(false);

            return (
                <React.Fragment>
                    {displayAttr === "none" ? this.renderLoadingProgressIndicator() : ""}
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{display: displayAttr, backgroundColor: "white", height: "calc(100% - 60px)", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId + " leopard_timelineviewer_container"}>
                        <LeopardTimelineConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            dataSourceUrl={this.props.dataSourceUrl}
                            definition={this.state.timelineDefinition}
                            dataViewId={this.props.dataViewId}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataInitializedOnControlsUpdateRequest={(e) => this.dataInitializedOnControlsUpdateRequest(e)}
                            dataInitializedOnControls={this.state.dataInitializedOnControls}
                            setTimelineInstance={this.props.setTimelineInstance}/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "tabbed-view") {
            if (this.props.dataViewId === undefined || this.props.dataViewId === null ||
                this.state.tabbedViewDefinition === undefined || this.state.tabbedViewDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }

            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         className={"dataview_" + this.props.dataViewId + " leopard_tabbedview_viewer_container"}>
                        <LeopardTabbedViewConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            definition={this.state.tabbedViewDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                            dataViewId={this.props.dataViewId}/>
                    </div>
                </React.Fragment>
            );
        }

        if (this.props.dataViewType === "diagram") {
            if (this.props.dataViewId === null || this.state.diagramDefinition === null) {
                return this.renderLoadingProgressIndicator();
            }
            return (
                <React.Fragment>
                    <div custom_attr_menuitemid={this.props.menuItemId}
                         style={{backgroundColor: "white", height: "100%", overflow: "hidden"}}
                         className={"dataview_" + this.props.dataViewId}>
                        <LeopardDiagramConfiguration
                            isLoadForTabbedView={this.props.isLoadForTabbedView}
                            changeTabbedViewTabId={this.props.changeTabbedViewTabId}
                            dataViewId={this.props.dataViewId} isDataView={true}
                            diagramDefinition={this.state.diagramDefinition}
                            updateWindowDimensionsRequired={this.props.updateWindowDimensionsRequired}
                        />
                    </div>
                </React.Fragment>
            );
        }

        return (<React.Fragment>
            <div>Data not found</div>
        </React.Fragment>);
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardSingleDataView);
