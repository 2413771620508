import React, {Component} from 'react';
import LRH from '../helpers/LeopardReactHelper';
import {connect} from "react-redux";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LDH from "../helpers/LeopardDataHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import $ from "jquery";
import momenttz from "moment-timezone";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardQueryHelper from "../helpers/LeopardQueryHelper";
import LeopardAPIGatewayConfig from "../foundation/LeopardAPIGatewayConfig";

class LeopardReportConfiguration extends Component {
    constructor(props) {
        super(props);

        this.reportViewerInstance = null;
        this.reportDesignerInstance = null;
        this.reportLayout = null;
        this.reportGenerationInterval = null;
        this.parametersInitialized = false;
    }

    initializeReportWithDataset = (data, that, isLoadReportLayout, isExecuteScripts) => {
        let report = new window.Stimulsoft.Report.StiReport();
        let dataSet = new window.Stimulsoft.System.Data.DataSet("ReportDataSet");
        let dataSetTimeZone = new window.Stimulsoft.System.Data.DataSet("ReportTimeZone");
        let timezones = LeopardDropdownHelper.DropdownSelectionTimeZone;

        let defaultDS = [];
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data["_defaultDS"])) {
            defaultDS = data["_defaultDS"];
        }
        dataSet.readJson({data: defaultDS});
        dataSetTimeZone.readJson({timezones});

        report.dictionary.dataStore.clear();
        report.regData(dataSet.dataSetName, "", dataSet);
        report.regData(dataSetTimeZone.dataSetName, "", dataSetTimeZone);

        let customDataSetList = [];
        if (!LDH.IsObjectNull(that.props.reportDefinition.reportCustomDataSources)) {
            let sources = that.props.reportDefinition.reportCustomDataSources;
            for (let v = 0; v < sources.length; v++) {
                let dsName = sources[v].name;
                let customDS = new window.Stimulsoft.System.Data.DataSet(dsName);

                let customData = [];
                if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data[dsName])) {
                    customData = data[dsName];
                }

                let jsonData = {[dsName.toLowerCase()]: customData};
                customDS.readJson(jsonData);
                report.regData(dsName, "", customDS);
                customDataSetList.push({name: dsName, dataset: customDS});
            }
        }

        if (isLoadReportLayout && !LDH.IsObjectNull(that.props.reportDefinition.reportLayout) &&
            !LDH.IsValueEmpty(that.props.reportDefinition.reportLayout)) {
            report.load(that.props.reportDefinition.reportLayout);

            let timezoneName = momenttz.tz.guess();
            if (LDH.IsValueEmpty(timezoneName)) timezoneName = "Australia/Sydney";

            let varibles = report.dictionary.variables;
            if (!LDH.IsObjectNull(varibles.getByName("Input_TimezoneName")) &&
                LDH.IsValueEmpty(varibles.getByName("Input_TimezoneName").valueObject)) {
                varibles.getByName("Input_TimezoneName").valueObject = timezoneName;
            }

            if (!LDH.IsObjectNull(varibles.getByName("Input_TimezoneOffset"))) {
                let tz = momenttz().tz(timezoneName).format();
                let zone = momenttz.parseZone(tz);
                let offset = zone.utcOffset() / 60;
                varibles.getByName("Input_TimezoneOffset").valueObject = offset;
            }

            if (isExecuteScripts) {
                let jsCode = that.props.reportDefinition.clientSideUILogicForReport;
                if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                    jsCode = LDH.FilterMacro(jsCode);
                    LRH.ExecuteClientScript(report, jsCode, "report", false);
                }
            }
        }

        return {report: report, datasets: {dataSet, dataSetTimeZone, customDataSetList}};
    };

    hideSystemInputFields = () => {
        let count = 0;
        let interval = setInterval(function () {
            $(".stiJsViewerFormButton.stiJsViewerFormButtonDefault").each(function () {
                let tdStyle = "td.stiJsViewerClearAllStyles";
                if ($(tdStyle, $(this)).length > 0 && $(tdStyle, $(this)).is(":visible") &&
                    $(tdStyle, $(this)).text().trim() === "Reset") {
                    $(this).hide();
                    $(".stiJsViewerInnerParametersPanelSimple td").each(function () {
                        if ($(this).text().trim().toLowerCase() === "input_timezoneoffset") {
                            $(this).hide();
                            $(this).next().hide();
                        }
                    });
                    if (!LDH.IsObjectNull(interval)) {
                        clearInterval(interval);
                    }
                }
            });
            if (count >= 300 && !LDH.IsObjectNull(interval)) {
                clearInterval(interval);
            }
            count++;
        }, 10);
    };

    initializeReportViewerOnExit = (data, that) => {
        let result = that.initializeReportWithDataset(data, that, true, false);
        that.reportViewerInstance.report = result.report;
        that.hideSystemInputFields();
    };

    initializeReportViewerOnInteraction = (data, that, variables) => {
        let result = that.initializeReportWithDataset(data, that, true, true);
        that.reportViewerInstance.report = result.report;
        that.hideSystemInputFields();

        let dict = result.report.dictionary;
        let keys = Object.keys(variables);

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            let value = variables[keys[i]];
            let input = dict.variables.getByName(key);

            if (!LDH.IsObjectNull(input)) {
                input.valueObject = value;
            }
        }
    };

    initializeReportViewerOnDesign = (data, that, thisViewer) => {
        let result = that.initializeReportWithDataset(data, that, true, false);
        that.reportDesignerInstance.report = result.report;
        that.reportDesignerInstance.visible = true;
        thisViewer.visible = false;
        that.hideSystemInputFields();
    };

    initializeReportViewerOnCompDidMount = (data, that) => {
        if (!LDH.IsObjectNull(that.props.reportDefinition) &&
            !LDH.IsObjectNull(that.props.reportDefinition.reportLayout) &&
            !LDH.IsObjectNull(that.props.reportDefinition.reportLayout.ReportName)) {
            that.reportLayout = that.props.reportDefinition.reportLayout;
            window["ReportLayout_OnPage_" + that.props.dataViewId] = that.reportLayout;
        }
        let result = that.initializeReportWithDataset(data, that, true, false);
        that.reportViewerInstance.report = result.report;
        that.hideSystemInputFields();
    };

    stiProgress = () => {
        try {
            let progressContainer = document.createElement("div");
            progressContainer.className = "leopard-report-loading-spinner";

            let progress = document.createElement("div");
            progressContainer.appendChild(progress);
            progress.className = "mobile_designer_loader";
            return progressContainer;
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    showProgress = (containerName) => {
        try {
            this.hideProgress();
            let rightPanel = document.getElementById(containerName);
            let progress = this.stiProgress();

            if (!LDH.IsObjectNull(rightPanel)) {
                rightPanel.appendChild(progress);
                rightPanel.progress = progress;
            }
            $(".leopard-report-loading-spinner-built-in").hide();
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    hideProgress = (containerName) => {
        try {
            let rightPanel = document.getElementById(containerName);
            if (!LDH.IsObjectNull(rightPanel) &&
                !LDH.IsObjectNull(rightPanel.progress) &&
                rightPanel.progress) {
                rightPanel.removeChild(rightPanel.progress);
                rightPanel.progress = null;
            }
            $(".js_viewer_loader").each(function () {
                if ($(this).parent().hasClass("leopard-report-loading-spinner")) {
                    return;
                }
                $(this).parent().addClass("leopard-report-loading-spinner-built-in");
            });
        } catch (ex) {
            console.log("Unhandled exception: ", ex);
        }
    };

    createReportDesigner() {
        let that = this;
        let options = new window.Stimulsoft.Designer.StiDesignerOptions();
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportViewerAppearanceShowTooltipsHelp;
        options.appearance.fullScreenMode = true;
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportViewerToolbarShowAboutButton;
        options.toolbar.showFileMenuAbout = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuAbout;
        options.toolbar.showFileMenuHelp = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuHelp;
        options.toolbar.showFileMenuInfo = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuInfo;
        options.toolbar.showFileMenuExit = LeopardStaticUIConfig.ReportViewerToolbarShowFileMenuExit;

        this.reportDesignerInstance = new window.Stimulsoft.Designer.StiDesigner(
            options, "LeopardReportDesigner", false);

        this.reportDesignerInstance.onExit = function () {
            this.visible = false;
            that.reportViewerInstance.visible = true;
            that.initializeReportViewerOnExit(null, that);
        };

        this.reportDesignerInstance.onSaveReport = function (args) {
            let jsonReport = args.report.saveToJsonString();
            let dataViewId = that.props.dataViewId;
            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);
            let reportDef = that.props.reportDefinition;

            reportDef.reportLayout = JSON.parse(jsonReport);
            that.reportLayout = jsonReport;
            window["ReportLayout_OnPage_" + that.props.dataViewId] = jsonReport;

            LeopardAjaxHelper.UpdateDataViewDefinition(userId, organizationId, dataViewId, reportDef, function () {
                let settingsVersionOnClient = parseInt($(".dataview-settings-version").attr("settingsversion"));
                $(".dataview-settings-version").attr("settingsversion", settingsVersionOnClient + 1);

                LRH.ShowToast("Your report has been successfully updated.", "success", 5000);
            }, function (error, sessionTimeout) {
                if (error === "version-out-of-date") {
                    LRH.ShowStaticToast("Configuration outdated", "Your current configuration settings " +
                        "cannot be saved due to a newer version found on the server, please refresh your " +
                        "browser. ", "error", true);
                } else if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to update your report. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
                that.setState({showLoadingProgress: false});
            });
        };

        this.reportDesignerInstance.renderHtml("reportDesignerContent");
        LRH.TriggerWindowResizeEvent();
    }

    createReportViewer() {
        let that = this;
        let userProfile = window.userProfile;
        let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

        let options = new window.Stimulsoft.Viewer.StiViewerOptions();
        options.appearance.interfaceType = window.Stimulsoft.Viewer.StiInterfaceType.Mouse;
        options.appearance.showTooltipsHelp = LeopardStaticUIConfig.ReportDesignerAppearanceShowTooltipsHelp;

        if (this.props.state.permissions.AllowReportDesigner) {
            options.toolbar.showDesignButton = LeopardStaticUIConfig.ReportDesignerToolbarShowDesignButton;
        }
        options.toolbar.showAboutButton = LeopardStaticUIConfig.ReportDesignerToolbarShowAboutButton;
        options.toolbar.showOpenButton = LeopardStaticUIConfig.ReportDesignerToolbarShowOpenButton;

        options.exports.showExportToText = false;
        options.exports.showExportToPowerPoint = false;
        options.exports.showExportToOpenDocumentCalc = false;
        options.exports.showExportToOpenDocumentWriter = false;
        options.exports.showExportToJson = false;
        options.exports.showExportToCsv = false;
        options.exports.showExportToWord2007 = false;
        options.exports.showExportToDocument = false;

        this.reportViewerInstance = new window.Stimulsoft.Viewer.StiViewer(
            options, "LeopardReportViewer", false);

        this.reportViewerInstance.onInteraction = function (args) {
            let dataViewId = that.props.dataViewId;
            if (args.action.toLowerCase() === "initvars") {
                let dict = args.report.dictionary;
                if (args === null || args.report === null || args.report.variables === null ||
                    args.report.variables.keys === null) {
                    LRH.ShowToast("Failed to generate a report, please try again.", "error", 5000);
                    return;
                }

                for (let i = 0; i < args.report.variables.keys.length; i++) {
                    let key = args.report.variables.keys[i];
                    let input = dict.variables.getByName(key);

                    if (!LDH.IsObjectNull(input)) {
                        if (!that.parametersInitialized) {
                            let newValue = LDH.FilterMacro(input.val, null, null);
                            input.valueObject = newValue;

                            if (!LDH.IsObjectNull(input.description) &&
                                !LDH.IsValueEmpty(input.description)) {
                                newValue = LDH.FilterMacro(input.description, null, null);
                                input.valueObject = newValue;
                            }
                        }
                    }
                }

                window["report_obj_" + that.props.dataViewId] = args.report;
                let accessibleData = [];
                for (let i = 0; i < args.report.variables.keys.length; i++) {
                    let key = args.report.variables.keys[i];
                    let input = dict.variables.getByName(key);

                    if (!LDH.IsObjectNull(input)) {
                        accessibleData.push({name: key, value: input.valueObject});
                    }
                }

                if (!LDH.IsObjectNull(that.props.reportDefinition.inputValuesInitLogic) &&
                    !LDH.IsValueEmpty(that.props.reportDefinition.inputValuesInitLogic)) {
                    let javascript = that.props.reportDefinition.inputValuesInitLogic;
                    let dataName = "data";
                    let dataValue = accessibleData;
                    let dataResult = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName,
                        dataValue, that.props.dataViewId, null);

                    for (let ii = 0; ii < args.report.variables.keys.length; ii++) {
                        let key = args.report.variables.keys[ii];
                        let inputCtrl = dict.variables.getByName(key);

                        for (let jj = 0; jj < dataResult.length; jj++) {
                            if (!LDH.IsObjectNull(inputCtrl) && dataResult[jj].name === key) {
                                if (typeof dataResult[jj].value === "string") {
                                    inputCtrl.valueObject = LDH.FilterMacro(dataResult[jj].value, null, null);
                                } else {
                                    inputCtrl.valueObject = dataResult[jj].value;
                                }
                            }
                        }
                    }
                }
                that.parametersInitialized = true;
                that.hideSystemInputFields();
            }
            if (args.action.toLowerCase() === "variables") {
                window["report_input_variables"] = args;

                let reportDef = that.props.reportDefinition;
                let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForNormalReport) ?
                    "" : "?" + reportDef.oDataParametersForNormalReport;

                let parentQuery1 = reportDef.oDataParametersParent1ForNormalReport;
                let parentQuery2 = reportDef.oDataParametersParent2ForNormalReport;
                let parentQuery3 = reportDef.oDataParametersParent3ForNormalReport;
                let keys = Object.keys(args.variables);

                parameters = LDH.GetSanitizedParameters(args, keys, parameters, true);
                parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
                parameters = LDH.FilterMacro(parameters);

                if (!LDH.IsObjectNull(parentQuery1) && !LDH.IsValueEmpty(parentQuery1)) {
                    parentQuery1 = LDH.GetSanitizedParameters(args, keys, parentQuery1, false);
                    parentQuery1 = LDH.ReplaceAll(parentQuery1, "{Input_UserGroupId}", organizationId);
                    parentQuery1 = LDH.FilterMacro(parentQuery1);
                }

                if (!LDH.IsObjectNull(parentQuery2) && !LDH.IsValueEmpty(parentQuery2)) {
                    parentQuery2 = LDH.GetSanitizedParameters(args, keys, parentQuery2, false);
                    parentQuery2 = LDH.ReplaceAll(parentQuery2, "{Input_UserGroupId}", organizationId);
                    parentQuery2 = LDH.FilterMacro(parentQuery2);
                }

                if (!LDH.IsObjectNull(parentQuery3) && !LDH.IsValueEmpty(parentQuery3)) {
                    parentQuery3 = LDH.GetSanitizedParameters(args, keys, parentQuery3, false);
                    parentQuery3 = LDH.ReplaceAll(parentQuery3, "{Input_UserGroupId}", organizationId);
                    parentQuery3 = LDH.FilterMacro(parentQuery3);
                }

                if (!LDH.IsObjectNull(reportDef.scriptShapingForReport) &&
                    !LDH.IsValueEmpty(reportDef.scriptShapingForReport)) {
                    try {
                        eval(reportDef.scriptShapingForReport);
                    } catch (ex) {
                        console.log("Failed to execute scriptShapingForReport.");
                    }
                }

                let tableNameWithParams = reportDef.tableName + parameters;
                if (reportDef.tableName === "custom-query") {
                    parameters = parameters.replace("?", "");
                    tableNameWithParams = parameters;
                }

                if (!LDH.IsObjectNull(reportDef.filterValidationsForReport) &&
                    !LDH.IsValueEmpty(reportDef.filterValidationsForReport)) {
                    let javascript = reportDef.filterValidationsForReport;
                    let dataName = "filters";
                    let dataValue = args.variables;
                    let result = LDH.EvaluateJavaScriptForDataShaping(javascript, dataName, dataValue, dataViewId);

                    if (result !== true) {
                        LRH.ShowToast(result, "error", 5000);
                        return;
                    }
                }

                if (!LDH.IsValueEmpty(tableNameWithParams) && tableNameWithParams.indexOf("{") > -1 &&
                    tableNameWithParams.indexOf("}") > -1) {
                    if (tableNameWithParams.indexOf("ignoreValidation=true") === 0) {
                        that.initializeReportViewerOnInteraction(null, that, args.variables);
                        return;
                    }
                }

                that.showProgress("reportViewerContent");
                let apiUrl = LDH.GetODataAPIGatewayUrl("");

                if (reportDef.dataSourceVersion === "v2") {
                    apiUrl = ""
                } else if (reportDef.dataSourceVersion === "v2-athena") {
                    apiUrl = LDH.GetAthenaAPIGatewayUrl("");
                } else {
                    if (reportDef.tableName === "custom-query") {
                        apiUrl = LDH.GetODataAPIGatewayUrl("");
                    } else {
                        apiUrl = apiUrl + "/" + reportDef.tableName;
                    }
                }

                if (reportDef.dataSourceVersion !== "v2-athena") {
                    let postDataQuery = reportDef.clientSideQueryForReport;
                    return LeopardQueryHelper.ExecuteQueriesForReport(apiUrl, parentQuery1, parentQuery2, parentQuery3, parameters, postDataQuery, [], function (data) {
                        let resultData = {};
                        let ajaxRequestObj = [];

                        if (!LDH.IsObjectNull(data) && typeof data.finalData !== "undefined") {
                            data = data.finalData;
                        }

                        resultData["_defaultDS"] = data;
                        let ajaxCount = 0;
                        let ajaxAdditionalCount = 0;

                        if (!LDH.IsObjectNull(reportDef.reportCustomDataSources)) {
                            for (let v = 0; v < reportDef.reportCustomDataSources.length; v++) {
                                let customQuery = reportDef.reportCustomDataSources[v].query;
                                let dsName = reportDef.reportCustomDataSources[v].name;
                                let postDataQuery = reportDef.reportCustomDataSources[v].postDataQuery;
                                let customParentQuery1 = reportDef.reportCustomDataSources[v].parentQuery1;
                                let customParentQuery2 = reportDef.reportCustomDataSources[v].parentQuery2;
                                let customParentQuery3 = reportDef.reportCustomDataSources[v].parentQuery3;

                                customQuery = LDH.GetSanitizedParameters(args, keys, customQuery, false);
                                customQuery = LDH.ReplaceAll(customQuery, "{Input_UserGroupId}", organizationId);
                                customQuery = LDH.FilterMacro(customQuery);

                                if (!LDH.IsObjectNull(customParentQuery1) && !LDH.IsValueEmpty(customParentQuery1)) {
                                    customParentQuery1 = LDH.GetSanitizedParameters(args, keys, customParentQuery1, false);
                                    customParentQuery1 = LDH.ReplaceAll(customParentQuery1, "{Input_UserGroupId}", organizationId);
                                    customParentQuery1 = LDH.FilterMacro(customParentQuery1);
                                    if (!LDH.IsValueEmpty(customParentQuery1)) ajaxAdditionalCount++;
                                }

                                if (!LDH.IsObjectNull(customParentQuery2) && !LDH.IsValueEmpty(customParentQuery2)) {
                                    customParentQuery2 = LDH.GetSanitizedParameters(args, keys, customParentQuery2, false);
                                    customParentQuery2 = LDH.ReplaceAll(customParentQuery2, "{Input_UserGroupId}", organizationId);
                                    customParentQuery2 = LDH.FilterMacro(customParentQuery2);
                                    if (!LDH.IsValueEmpty(customParentQuery2)) ajaxAdditionalCount++;
                                }

                                if (!LDH.IsObjectNull(customParentQuery3) && !LDH.IsValueEmpty(customParentQuery3)) {
                                    customParentQuery3 = LDH.GetSanitizedParameters(args, keys, customParentQuery3, false);
                                    customParentQuery3 = LDH.ReplaceAll(customParentQuery3, "{Input_UserGroupId}", organizationId);
                                    customParentQuery3 = LDH.FilterMacro(customParentQuery3);
                                    if (!LDH.IsValueEmpty(customParentQuery3)) ajaxAdditionalCount++;
                                }

                                if (!LDH.IsObjectNull(reportDef.reportCustomDataSources[v].queryShaping) &&
                                    !LDH.IsValueEmpty(reportDef.reportCustomDataSources[v].queryShaping)) {
                                    try {
                                        eval(reportDef.reportCustomDataSources[v].queryShaping);
                                    } catch (ex) {
                                        console.log("Failed to execute queryShaping.");
                                    }
                                }

                                if (LDH.IsValueEmpty(customQuery) === false) {
                                    LeopardQueryHelper.ExecuteQueriesForReport(apiUrl, customParentQuery1, customParentQuery2, customParentQuery3,
                                        customQuery, postDataQuery, ajaxRequestObj, function (data, queryType, ajaxTotalCount) {
                                            if (!LDH.IsObjectNull(data) && typeof data.finalData !== "undefined") {
                                                data = data.finalData;
                                            }
                                            resultData[dsName] = data;
                                            ajaxCount += ajaxTotalCount;
                                        }, function () {
                                            resultData[dsName] = null;
                                            ajaxCount++;
                                        });
                                } else {
                                    resultData[dsName] = [];
                                    if (!LDH.IsObjectNull(postDataQuery) && !LDH.IsValueEmpty(postDataQuery)) {
                                        resultData[dsName] = LRH.ExecuteClientScript([], postDataQuery, "data", true);
                                    }
                                    ajaxCount++;
                                }
                            }
                        }

                        let interval = setInterval(function () {
                            var count = 0;
                            if (typeof reportDef.reportCustomDataSources !== "undefined") {
                                count = reportDef.reportCustomDataSources.length;
                                count += ajaxAdditionalCount;
                            }
                            if (ajaxCount >= count) {
                                if (interval !== null) clearInterval(interval);
                                Promise.all(ajaxRequestObj).then(() => {
                                    that.initializeReportViewerOnInteraction(resultData, that, args.variables);
                                    that.hideProgress("reportViewerContent");
                                }).catch(() => {
                                    that.initializeReportViewerOnInteraction(null, that, args.variables);
                                    that.hideProgress("reportViewerContent");
                                });
                            }
                        }, 100);
                    }, function (data) {
                        that.initializeReportViewerOnInteraction(null, that, args.variables);
                        that.hideProgress("reportViewerContent");
                    });
                } else {
                    let customDataSources = [];
                    if (!LDH.IsObjectNull(reportDef.reportCustomDataSources)) {
                        customDataSources = LDH.GetCustomDataSources(reportDef, args, keys, organizationId);
                    }
                    let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
                    let $menuItem = $(".leopard-leftmenu-item.selected");
                    let menuFolderId = $menuItem.attr("parentid").trim();

                    let dataToPost = {
                        isScheduledReport: false,
                        defaultDataSource: {
                            parentQuery1: parentQuery1,
                            parentQuery2: parentQuery2,
                            parentQuery3: parentQuery3,
                            finalQuery: parameters,
                            dataSourceName: "defaultDataSource"
                        },
                        dataViewId: that.props.dataViewId,
                        menuFolderId: menuFolderId,
                        customDataSources: customDataSources,
                        loginUserId: loginUserId,
                        reportArgs: args.variables,
                        reportConfig: LDH.DeepClone(reportDef)
                    };
                    window.reportAthenaResults = null;

                    let directory = "report";
                    let fileName = reportDef.reportName;
                    let ownerType = "userid";

                    if (LDH.IsValueEmpty(directory) || LDH.IsValueEmpty(fileName) ||
                        LDH.IsValueEmpty(ownerType)) {
                        LRH.ShowToast("Validation failed. " +
                            "The directory, file name or owner type cannot be blank.", "error", 5000);
                        return;
                    }

                    let documentModifyType = "leopardsystems.report.modify";
                    let documentCreateType = "leopardsystems.report.create";
                    let documentListType = "leopardsystems.report.list";
                    let documentRetrieveType = "leopardsystems.report.retrieve";
                    let fileNameFormat = "YYYY-MM-DD HH-mm-ss SSSSSS";
                    LeopardAjaxHelper.RetrieveDocumentFromS3(loginUserId, fileName, directory, function (documentData) {
                        let jsonData = (documentData.indexOf("<Code>NoSuchKey</Code>") > -1 || documentData.indexOf("<!DOCTYPE html>") > -1)
                            ? null : JSON.parse(documentData);

                        if (jsonData === null || jsonData.reportConfig.reportDataViewVersion !== dataToPost.reportConfig.reportDataViewVersion) {
                            LeopardAjaxHelper.InsertOrUpdateDocumentToS3(dataToPost, fileName, directory, function () {
                                // Add 1000s because the document might not be created.
                                setTimeout(function () {
                                    LeopardAjaxHelper.ListDocumentToS3(loginUserId, function (documentListResponse) {
                                        let document = null;
                                        let documentList = documentListResponse.body.data;
                                        if (typeof documentList === "undefined" || documentList === null || documentList === "") documentList = [];
                                        for (let i = 0; i < documentList.length; i++) {
                                            if (documentList[i].owner.toLowerCase() === loginUserId.toLowerCase() && documentList[i].name === fileName &&
                                                documentList[i].type === directory) {
                                                document = documentList[i];
                                                break;
                                            }
                                        }
                                        directory = "report_results";
                                        fileName = momenttz().tz("Australia/Sydney").format(fileNameFormat);
                                        that.sendRequestToAthenaService(directory, fileName, ownerType, dataToPost, that, reportDef, args, document);
                                    }, null, documentListType);
                                }, 1000);
                            }, function () {
                                LRH.ShowToast("Failed to send your request to the reporting service.", "error", 5000);
                                that.hideProgress("reportViewerContent");
                            }, ownerType, documentListType, documentModifyType, documentCreateType);
                            return;
                        }

                        LeopardAjaxHelper.ListDocumentToS3(loginUserId, function (documentListResponse) {
                            let document = null;
                            let documentList = documentListResponse.body.data;
                            if (typeof documentList === "undefined" || documentList === null || documentList === "") documentList = [];
                            for (let i = 0; i < documentList.length; i++) {
                                if (documentList[i].owner.toLowerCase() === loginUserId.toLowerCase() && documentList[i].name === fileName &&
                                    documentList[i].type === directory) {
                                    document = documentList[i];
                                    break;
                                }
                            }
                            directory = "report_results";
                            fileName = momenttz().tz("Australia/Sydney").format(fileNameFormat);
                            that.sendRequestToAthenaService(directory, fileName, ownerType, dataToPost, that, reportDef, args, document);
                        }, null, documentListType);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                        }
                        that.hideProgress("reportViewerContent");
                    }, documentRetrieveType);
                }
            }
        };

        this.reportViewerInstance.onDesignReport = function () {
            that.createReportDesigner();
            let thisViewer = this;
            that.initializeReportViewerOnDesign(null, that, thisViewer);
        };
        this.reportViewerInstance.renderHtml("reportViewerContent");
    }

    sendRequestToAthenaService(directory, fileName, ownerType, dataToPost, thisComp, reportDef, args, document) {
        let documentModifyType = "leopardsystems.report.modify";
        let documentCreateType = "leopardsystems.report.create";
        let documentListType = "leopardsystems.report.list";
        let that = thisComp;
        let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
        dataToPost.payloadId = LDH.GenerateGuid();

        let isNotificationEnabled = false;
        if (typeof reportDef.notificationEnabled !== "undefined" &&
            reportDef.notificationEnabled !== null) {
            isNotificationEnabled = reportDef.notificationEnabled;
        }
        let notificationMetadataNew = {
            notificationEnabled: isNotificationEnabled,
            notificationStatus: []
        };
        notificationMetadataNew.notificationStatus.push({
            userId: loginUserId, notified: false
        });
        let $menuItem = $(".leopard-leftmenu-item.selected");
        let menuFolderId = $menuItem.attr("parentid").trim();

        let metadata = {
            parent_documentId: document.documentid,
            parent_documentVersion: document.version,
            reportArgs: args.variables,
            notification: notificationMetadataNew,
            interaction: {
                reportName: reportDef.reportName,
                menuFolderId: menuFolderId,
                renderingMethod: reportDef.renderingMethod,
                dataViewId: thisComp.props.dataViewId,
                loginUserId: loginUserId,
                timestamp: LDH.GetNowLocal()
            }
        };

        LeopardAjaxHelper.InsertOrUpdateDocumentToS3(dataToPost, fileName, directory, function () {
            let inProgress = false;
            let finishWaiting = false;
            let listDocInterval = setInterval(function () {
                if (inProgress || finishWaiting) return;

                inProgress = true;
                LeopardAjaxHelper.ListDocumentToS3(loginUserId, function (response) {
                    let reportsList = response.body.data;
                    let report = null;
                    if (reportsList === null || reportsList === "") reportsList = [];

                    reportsList.sort(function (a, b) {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    });

                    for (let i = 0; i < reportsList.length; i++) {
                        if (reportsList[i].owner.toLowerCase() === loginUserId.toLowerCase() && reportsList[i].name === fileName &&
                            reportsList[i].type === directory) {
                            report = reportsList[i];
                            break;
                        }
                    }
                    inProgress = false;
                    if (report === null) return;

                    if (listDocInterval !== null) clearInterval(listDocInterval);
                    finishWaiting = true;

                    let serviceRequestType = "leopardsystems.report.run.athena";
                    report.owner = report.owner.toLowerCase();

                    LeopardAjaxHelper.SendEventToReportService(report, function (response) {
                        LRH.ShowToast("Request sent. We'll let you know once the report is available.", "success", 8000);
                        thisComp.hideProgress("reportViewerContent");

                        let argsCloned = LDH.DeepClone({variables: args.variables});
                        let reportDefCloned = LDH.DeepClone(reportDef);
                        let reportGenerationIntervalId = "reportIntervalId_" + LDH.GenerateGuid();

                        window[reportGenerationIntervalId] = setInterval(function () {
                            if (typeof window.reportAthenaResults !== "undefined" &&
                                window.reportAthenaResults !== null) {
                                if (window[reportGenerationIntervalId] !== null) {
                                    clearInterval(window[reportGenerationIntervalId]);
                                }
                                if (window.reportAthenaResults.action === "stop") {
                                    window.reportAthenaResults = null;
                                    return;
                                }
                                if (window.reportAthenaResults.action === "render") {
                                    let clonedResults = LDH.DeepClone(window.reportAthenaResults);
                                    window.reportAthenaResults = null;

                                    let completedDS = clonedResults.data.reportMetadata.completedDataSources;
                                    LeopardAjaxHelper.SendEventToReportService(completedDS, function (response) {
                                        let datasources = response.body.data.data;
                                        clonedResults.data.reportMetadata.completedDataSources = datasources;
                                        that.getAthenaDataForReport(clonedResults.data, reportDefCloned, argsCloned);
                                    }, function (error, sessionTimeout) {
                                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                                        } else {
                                            LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                                        }
                                    }, "leopardsystems.report.signurl");
                                }
                            }
                        }, 500);
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                        }
                        thisComp.hideProgress("reportViewerContent");
                    }, serviceRequestType);
                }, null, documentListType);
            }, 1000);
        }, function () {
            LRH.ShowToast("Failed to send your request to the reporting service.", "error", 5000);
            that.hideProgress("reportViewerContent");
        }, ownerType, documentListType, documentModifyType, documentCreateType, metadata);
    };

    getAthenaDataForReport(results, reportDef, args) {
        let jsonUrls = [];
        let that = this;
        let completedDataSources = results.reportMetadata.completedDataSources;

        for (let i = 0; i < completedDataSources.length; i++) {
            jsonUrls.push(completedDataSources[i].signedUrl);
        }
        let jsonDataArray = [];

        function downloadReportData(url, index) {
            if (typeof url === "undefined" || url === null) return;
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'text';

            xhr.onload = function () {
                if (xhr.status === 200) {
                    let data = xhr.responseText;
                    jsonDataArray[index] = {url: url};
                    for (let i = 0; i < completedDataSources.length; i++) {
                        if (completedDataSources[i].signedUrl !== url) {
                            continue;
                        }
                        completedDataSources[i].jsonData = JSON.parse(data);
                    }
                    if (jsonDataArray.length === jsonUrls.length) {
                        that.generateReportWithAthenaData(completedDataSources, reportDef, that, args);
                    }
                }
            };
            xhr.send();
        }

        jsonUrls.forEach((url, index) => downloadReportData(url, index));
    }

    generateReportWithAthenaData(completedDataSources, reportDef, that, args) {
        let reportDataList = {};
        for (let i = 0; i < completedDataSources.length; i++) {
            if (completedDataSources[i].name === "defaultDataSource") {
                let jsonData = completedDataSources[i].jsonData;
                reportDataList["_defaultDS"] = jsonData;
            } else {
                let jsonData = completedDataSources[i].jsonData;
                reportDataList[completedDataSources[i].name] = jsonData;
            }
        }
        that.initializeReportViewerOnInteraction(reportDataList, that, args.variables);
        that.hideProgress("reportViewerContent");
    }

    clearReportGenerationIntervals() {
        let keys = Object.keys(window);
        for (let i = 0; i < keys.length; i++) {
            if (keys[i].indexOf("reportIntervalId_") === -1) {
                continue;
            }
            if (window[keys[i]] === null) continue;

            if (window[keys[i]] !== null) {
                clearInterval(window[keys[i]]);
            }
            window[keys[i]] = null;
        }
    };

    componentWillUnmount() {
        window["report_input_variables"] = null;
        window["report_obj_" + this.props.dataViewId] = null;
        window["ReportLayout_OnPage_" + this.props.dataViewId] = null;
        this.clearReportGenerationIntervals();
    }

    componentDidMount() {
        let that = this;
        let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
        this.clearReportGenerationIntervals();

        if ($("#stimulsoft-viewer-css").length === 0) {
            $("head").prepend('<link rel="stylesheet" id="stimulsoft-viewer-css" type="text/css" ' +
                'href="/css/stimulsoft/2022.2.6/stimulsoft.viewer.office2013.whiteorange.css?v=' + version + '" />');
        }
        if ($("#stimulsoft-designer-css").length === 0) {
            $("head").prepend('<link rel="stylesheet" id="stimulsoft-designer-css" type="text/css" ' +
                'href="/css/stimulsoft/2022.2.6/stimulsoft.designer.office2013.whiteorange.css?v=' + version + '" />');
        }
        if ($("#stimulsoft-reports").length === 0) {
            $("head").append('<script id="stimulsoft-reports" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.reports.js?v=' + version + '"></script>');
        }
        if ($("#stimulsoft-viewer").length === 0) {
            $("head").append('<script id="stimulsoft-viewer" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.viewer.js?v=' + version + '"></script>');
        }
        if ($("#stimulsoft-designer").length === 0) {
            $("head").append('<script id="stimulsoft-designer" async ' +
                'src="/js/stimulsoft/2022.2.6/stimulsoft.designer.js?v=' + version + '"></script>');
        }
        window.Stimulsoft.Base.StiLicense.key = LeopardStaticUIConfig.ReportLicenseKey;

        let culture = window.Stimulsoft.System.Globalization.CultureInfo.cultures["en-AU"];
        window.Stimulsoft.System.Globalization.CultureInfo.currentCulture = culture;

        that.createReportViewer();
        that.initializeReportViewerOnCompDidMount(null, that);

        if (typeof window.generateReportFromNotification !== "undefined" &&
            window.generateReportFromNotification !== null) {
            that.showProgress("reportViewerContent");

            setTimeout(function () {
                let clonedResults = LDH.DeepClone(window.generateReportFromNotification);
                window.generateReportFromNotification = null;

                let reportArgs = {variables: clonedResults.responseData.reportMetadata.reportArgs};
                let reportDef = that.props.reportDefinition;
                let results = clonedResults.responseData;
                let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
                let document = results.reportMetadata.documentMetadata;
                let notificationToUpdate = [];
                let metadataToUpdate = {};

                if (typeof results.reportMetadata.metadataToUpdate !== "undefined" &&
                    typeof results.reportMetadata.metadataToUpdate.notification !== "undefined") {
                    notificationToUpdate = results.reportMetadata.metadataToUpdate.notification;

                    for (let i = 0; i < notificationToUpdate.notificationStatus.length; i++) {
                        let status = notificationToUpdate.notificationStatus[i];
                        if (status.userId !== loginUserId) continue;
                        status.notified = true;
                        break;
                    }
                    results.reportMetadata.metadataToUpdate.rowId = document.id;
                    metadataToUpdate = results.reportMetadata.metadataToUpdate;
                } else if (typeof document.event_json.metadata !== "undefined") {
                    if (typeof document.event_json.metadata === "string") {
                        document.event_json.metadata = JSON.parse(document.event_json.metadata);
                    }
                    notificationToUpdate = document.event_json.metadata.notification;

                    for (let i = 0; i < notificationToUpdate.notificationStatus.length; i++) {
                        let status = notificationToUpdate.notificationStatus[i];
                        if (status.userId !== loginUserId) continue;
                        status.notified = true;
                        break;
                    }
                    document.event_json.metadata.rowId = document.id;
                    metadataToUpdate = document.event_json.metadata;
                }

                let template = LeopardAPIGatewayConfig.ProfileAPI_BodyTemplate();
                template.id = LDH.GenerateGuid();
                template.type = "leopardsystems.report.modify.metadata";

                LeopardAjaxHelper.SendRequestByEventSync(function (e) {
                    LeopardAjaxHelper.RetrieveDocumentFromS3(loginUserId, document.name, document.type, function (data) {
                        let documentDataJSON = JSON.parse(data);
                        if (typeof documentDataJSON.reportMetadata === "undefined") {
                            LRH.ShowToast("Response is missing reportMetadata object.", "error", 5000);
                            that.hideProgress("reportViewerContent");
                            return;
                        }
                        let completedDS = documentDataJSON.reportMetadata.completedDataSources;
                        if (typeof completedDS === "undefined") {
                            LRH.ShowToast("Missing completedDataSources object.", "error", 5000);
                            that.hideProgress("reportViewerContent");
                            return;
                        }

                        LeopardAjaxHelper.SendEventToReportService(completedDS, function (response) {
                            let datasources = response.body.data.data;
                            for (let i = 0; i < datasources.length; i++) {
                                if (typeof datasources[i].signedUrl === "undefined"){
                                    LRH.ShowToast("Missing signedUrl object.", "error", 5000);
                                    that.hideProgress("reportViewerContent");
                                    return;
                                }
                            }
                            results.reportMetadata.completedDataSources = datasources;
                            that.getAthenaDataForReport(results, reportDef, reportArgs);
                        }, function (error, sessionTimeout) {
                            if (sessionTimeout !== undefined && sessionTimeout === true) {
                                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                            } else {
                                LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                            }
                            that.hideProgress("reportViewerContent");
                        }, "leopardsystems.report.signurl");
                    }, function (error, sessionTimeout) {
                        if (sessionTimeout !== undefined && sessionTimeout === true) {
                            LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                        } else {
                            LRH.ShowToast("Failed to retrieve document.", "error", 5000);
                        }
                        that.hideProgress("reportViewerContent");
                    }, "leopardsystems.report.retrieve");
                }, function (error) {
                    return;
                }, template, metadataToUpdate);
            }, 800);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id={"reportViewerContent"}></div>
                <div id={"reportDesignerContent"}></div>
            </React.Fragment>
        );
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};

export default connect(RetrieveDataFromReducer)(LeopardReportConfiguration);
