import React, {Component} from 'react';
import {Button, NumberBox, Popup, SelectBox} from 'devextreme-react';
import LRH from "../helpers/LeopardReactHelper";
import LDH from "../helpers/LeopardDataHelper";
import {connect} from "react-redux";
import Box, {Item} from 'devextreme-react/box';
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import {ButtonGroup} from 'devextreme-react/button-group';
import DateBox from "devextreme-react/date-box";
import RadioGroup from 'devextreme-react/radio-group';

class LeopardRecurrenceEditorPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recurrenceData: {},
            popupVisible: false,
            popupTitle: null,
            contentHeight: "",
            popupWidth: "90%",
            popupHeight: "90%",
            popupPosition: {
                my: 'center',
                at: 'center',
                of: window
            },
            repeatStartDate: new Date(),
            repeatOptions: "daily",
            repeatEvery: 1,
            repeatOnWeeks: [],
            repeatOnMonths: 1,
            repeatOnYearsMonth: 1,
            repeatOnYearsDay: 1,
            repeatEndOptions: "no-end-date",
            repeatEndByDate: new Date(),
            repeatEndByOccurrence: 1,
            resizeEnabled: false
        };

        this.parentDefinition = null;
        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
    }

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    addUIObjectInstance = (data, addToParent) => {
        this.uiObjectInstances[data.name] = data.instance;

        if (addToParent === true) {
            this.props.setComponentInstance({
                componentName: data.name,
                e: data.instance
            });
        }
    };

    popupOnHiding = () => {

    };

    popupOnShowing = (e) => {
        let title = this.props.popupTitle;
        let contentHeight = this.props.contentHeight;
        this.uiObjectInstances = [];
        this.parentDefinition = this.props.parentDefinition;

        let properties = {};
        if (LDH.IsObjectNull(window.Global_PopupTempData) ||
            LDH.IsObjectNull(window.Global_PopupTempData.recurrenceData) ||
            LDH.IsValueEmpty(window.Global_PopupTempData.recurrenceData)) {
            properties = {
                repeatStartDate: new Date(),
                repeatOptions: "daily",
                repeatEvery: 1,
                repeatOnWeeks: [],
                repeatOnMonths: 1,
                repeatOnYearsMonth: 1,
                repeatOnYearsDay: 1,
                repeatEndOptions: "no-end-date",
                repeatEndByDate: new Date(),
                repeatEndByOccurrence: 1
            };
        } else {
            let defaultRecurrenceData = window.Global_PopupTempData.recurrenceData;
            properties = {
                repeatStartDate: defaultRecurrenceData.repeatStartDate,
                repeatOptions: defaultRecurrenceData.repeatOptions,
                repeatEvery: defaultRecurrenceData.repeatEvery,
                repeatOnWeeks: defaultRecurrenceData.repeatOnWeeks,
                repeatOnMonths: defaultRecurrenceData.repeatOnMonths,
                repeatOnYearsMonth: defaultRecurrenceData.repeatOnYearsMonth,
                repeatOnYearsDay: defaultRecurrenceData.repeatOnYearsDay,
                repeatEndOptions: defaultRecurrenceData.repeatEndOptions,
                repeatEndByDate: defaultRecurrenceData.repeatEndByDate,
                repeatEndByOccurrence: defaultRecurrenceData.repeatEndByOccurrence
            };
        }
        if (LDH.IsObjectNull(window.Global_PopupTempData)) window.Global_PopupTempData = {};
        window.Global_PopupTempData.recurrenceData = null;

        if (!LDH.IsObjectNull(this.props.popupHeight) &&
            !LDH.IsObjectNull(this.props.popupWidth)) {
            this.setState({
                popupTitle: title,
                contentHeight: contentHeight,
                popupHeight: this.props.popupHeight,
                popupWidth: this.props.popupWidth,
                resizeEnabled: this.props.resizeEnabled,
                ...properties
            });
        } else {
            this.setState({
                popupTitle: title,
                contentHeight: contentHeight,
                resizeEnabled: this.props.resizeEnabled,
                ...properties
            });
        }
    };

    popupOnShown = () => {
        let that = this;
        if (that.instanceInitialized === false) {
            that.instanceInitialized = true;
            LRH.AddDisposablePopupInstances(that);
        }
    };

    onResizeEnd = (e) => {
        this.setState({
            popupHeight: e.component.option("height"),
            popupWidth: e.component.option("width")
        });
    };

    repeatStartDateValueOnChange = (e) => {
        this.setState({repeatStartDate: e.value});
    }

    repeatEndByDateValueOnChange = (e) => {
        this.setState({repeatEndByDate: e.value});
    }

    repeatEndByOccurrenceValueOnChange = (e) => {
        this.setState({repeatEndByOccurrence: e.value});
    }

    repeatEndOptionsValueOnChange = (e) => {
        this.setState({repeatEndOptions: e.value});
    }

    repeatOptionsValueOnChange = (e) => {
        this.setState({repeatOptions: e.value});
    };

    repeatEveryValueOnChange = (e) => {
        this.setState({repeatEvery: e.value});
    };

    repeatOnWeeksValueOnChange = (e) => {
        this.setState({repeatOnWeeks: e.value});
    };

    repeatOnMonthsValueOnChange = (e) => {
        this.setState({repeatOnMonths: e.value});
    };

    repeatOnYearsMonthValueOnChange = (e) => {
        this.setState({repeatOnYearsMonth: e.value});
    };

    repeatOnYearsDayValueOnChange = (e) => {
        this.setState({repeatOnYearsDay: e.value});
    };

    repeatNoEndDateTemplate = (data) => {
        return (
            <React.Fragment>
                <div className={"formField_container"}
                     style={{display: "flex", marginTop: "10px", marginBottom: "10px"}}>
                    <div style={{marginTop: "auto", marginBottom: "auto"}}>No end date</div>
                </div>
            </React.Fragment>
        );
    };

    repeatEndByDateTemplate = (data) => {
        let that = data.thisComp;
        return (
            <React.Fragment>
                <div className={"formField_container"} style={{display: "flex", marginTop: "5px", marginBottom: "5px"}}>
                    <div style={{
                        marginTop: "auto",
                        marginBottom: "auto",
                        whiteSpace: "nowrap",
                        marginRight: "10px",
                        minWidth: "60px"
                    }}>
                        End by:
                    </div>
                    <DateBox type={"date"} width={"100%"} style={{marginTop: "auto", marginBottom: "auto"}}
                             onValueChanged={that.repeatEndByDateValueOnChange}
                             disabled={that.state.repeatEndOptions !== "end-by-date"}
                             ref={(e) => that.addUIObjectInstance({
                                 name: "datepickerEndDate",
                                 instance: data.e
                             }, false)}
                             value={that.state.repeatEndByDate} displayFormat={"dd/MM/yyyy"}>
                    </DateBox>
                </div>
            </React.Fragment>
        );
    }

    repeatEndByOccurrenceTemplate = (data) => {
        let that = data.thisComp;
        return (
            <React.Fragment>
                <div className={"formField_container"} style={{display: "flex", marginTop: "5px", marginBottom: "5px"}}>
                    <div style={{
                        marginTop: "auto", marginBottom: "auto", whiteSpace: "nowrap", marginRight: "10px",
                        minWidth: "60px"
                    }}>
                        End after:
                    </div>
                    <NumberBox min={1} max={99999} showSpinButtons={true} format={"#"}
                               style={{marginTop: "auto", marginBottom: "auto"}}
                               disabled={that.state.repeatEndOptions !== "end-by-occurrence"}
                               onValueChanged={that.repeatEndByOccurrenceValueOnChange}
                               showClearButton={false} value={that.state.repeatEndByOccurrence}>
                    </NumberBox>
                    <span style={{marginTop: "auto", marginBottom: "auto", marginLeft: "10px"}}>occurrence(s)</span>
                </div>
            </React.Fragment>
        );
    }

    buttonOkOnClick = (e) => {
        let data = LDH.DeepClone({
            repeatStartDate: this.state.repeatStartDate,
            repeatOptions: this.state.repeatOptions,
            repeatEvery: this.state.repeatEvery,
            repeatOnWeeks: this.state.repeatOnWeeks,
            repeatOnMonths: this.state.repeatOnMonths,
            repeatOnYearsMonth: this.state.repeatOnYearsMonth,
            repeatOnYearsDay: this.state.repeatOnYearsDay,
            repeatEndOptions: this.state.repeatEndOptions,
            repeatEndByDate: this.state.repeatEndByDate,
            repeatEndByOccurrence: this.state.repeatEndByOccurrence
        });
        let id = this.props.recurrencePopupId;
        let parameter = this.props.parameter;
        let formParameters = this.props.formParameters;
        let inputboxId = this.props.inputBoxId;
        this.props.recurrenceEditorPopupButtonSaveOnClick(e, id, data, parameter, formParameters, inputboxId);
    }

    buttonCancelOnClick = (e) => {
        let id = this.props.recurrencePopupId;
        let parameter = this.props.parameter;
        let formParameters = this.props.formParameters;
        let inputboxId = this.props.inputBoxId;
        this.props.recurrenceEditorPopupButtonCancelOnClick(e, id, {}, parameter, formParameters, inputboxId);
    }

    render() {
        if (this.disposingAllInstances) return null;
        let dataViewId = this.props.dataViewId;
        let that = this;

        return (
            <Popup className={'leopard-popup-window recurrence-editor'} visible={this.state.popupVisible}
                   dragEnabled={true} hideOnOutsideClick={false} onHiding={this.popupOnHide}
                   onShowing={this.popupOnShowing} showTitle={true} title={this.state.popupTitle}
                   ref={this.props.popupRecurrenceEditorInstance} onShown={this.popupOnShown}
                   fullScreen={false} width={this.state.popupWidth} height={this.state.popupHeight}
                   resizeEnabled={this.state.resizeEnabled} minHeight={"100px"} minWidth={"100px"}
                   position={this.state.popupPosition} onResizeEnd={(e) => this.onResizeEnd(e)}>

                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{height: "395px"}}>
                        <form style={{overflowY: "auto"}}>
                            <div style={{padding: "10px"}}>
                                <Box direction="row" width="100%">
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "0"}}>Start date:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <DateBox type={"datetime"} width={"100%"}
                                                     onValueChanged={that.repeatStartDateValueOnChange}
                                                     ref={(e) => that.addUIObjectInstance({
                                                         name: "datepickerStartDate",
                                                         instance: e
                                                     }, false)}
                                                     value={that.state.repeatStartDate}
                                                     displayFormat={"dd/MM/yyyy HH:mm:ss"}>
                                            </DateBox>
                                        </div>
                                    </Item>
                                </Box>
                                <Box direction="row" width="100%">
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px"}}>Repeat:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <SelectBox displayExpr={'name'} valueExpr={'id'} width={"100%"}
                                                       value={that.state.repeatOptions}
                                                       style={{marginRight: "20px"}}
                                                       onValueChanged={that.repeatOptionsValueOnChange}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionRecurrenceRepeat}
                                                       ref={(e) => that.addUIObjectInstance({
                                                           name: "selectboxRepeat",
                                                           instance: e
                                                       }, false)}>
                                            </SelectBox>
                                        </div>
                                    </Item>
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px"}}>Repeat every:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <NumberBox min={1} max={99999} showSpinButtons={true} format={"#"}
                                                       onValueChanged={that.repeatEveryValueOnChange}
                                                       showClearButton={false} value={that.state.repeatEvery}>
                                            </NumberBox>
                                            <span style={{
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                marginLeft: "10px",
                                                display: that.state.repeatOptions === "hourly" ? "block" : "none"
                                            }}>hour(s)</span>
                                            <span style={{
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                marginLeft: "10px",
                                                display: that.state.repeatOptions === "daily" ? "block" : "none"
                                            }}>day(s)</span>
                                            <span style={{
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                marginLeft: "10px",
                                                display: that.state.repeatOptions === "weekly" ? "block" : "none"
                                            }}>week(s)</span>
                                            <span style={{
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                marginLeft: "10px",
                                                display: that.state.repeatOptions === "monthly" ? "block" : "none"
                                            }}>month(s)</span>
                                            <span style={{
                                                marginTop: "auto",
                                                marginBottom: "auto",
                                                marginLeft: "10px",
                                                display: that.state.repeatOptions === "yearly" ? "block" : "none"
                                            }}>year(s)</span>
                                        </div>
                                    </Item>
                                </Box>
                                <Box direction="row" width="100%" visible={that.state.repeatOptions === "weekly"}>
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px"}}>Repeat on:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <ButtonGroup width={"100%"}
                                                         selectedItemKeys={that.state.repeatOnWeeks}
                                                         items={LeopardDropdownHelper.RadioGroupSelectionDays}
                                                         keyExpr="text" stylingMode="outlined" selectionMode="multiple"
                                                         onItemClick={that.repeatOnWeeksValueOnChange}/>
                                        </div>
                                    </Item>
                                </Box>
                                <Box direction="row" width="100%" visible={that.state.repeatOptions === "monthly"}>
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px"}}>Repeat on:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <NumberBox min={1} max={31} showSpinButtons={true} format={"#"}
                                                       style={{marginRight: "20px"}}
                                                       onValueChanged={that.repeatOnMonthsValueOnChange}
                                                       showClearButton={false} value={that.state.repeatOnMonths}>
                                            </NumberBox>
                                        </div>
                                    </Item>
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px", height: "100%"}}></div>
                                    </Item>
                                </Box>
                                <Box direction="row" width="100%" visible={that.state.repeatOptions === "yearly"}>
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px"}}>Repeat on:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <SelectBox displayExpr={'name'} valueExpr={'id'} width={"100%"}
                                                       value={that.state.repeatOnYearsMonth}
                                                       style={{marginRight: "20px"}}
                                                       onValueChanged={that.repeatOnYearsMonthValueOnChange}
                                                       dataSource={LeopardDropdownHelper.DropdownSelectionMonths}
                                                       ref={(e) => that.addUIObjectInstance({
                                                           name: "selectboxRepeat",
                                                           instance: e
                                                       }, false)}>
                                            </SelectBox>
                                        </div>
                                    </Item>
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "10px", height: "100%"}}></div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <NumberBox min={1} max={31} showSpinButtons={true} format={"#"}
                                                       onValueChanged={that.repeatOnYearsDayValueOnChange}
                                                       width={"100%"}
                                                       showClearButton={false} value={that.state.repeatOnYearsDay}>
                                            </NumberBox>
                                        </div>
                                    </Item>
                                </Box>
                                <Box direction="row" width="100%">
                                    <Item ratio={1}>
                                        <hr style={{marginTop: "20px"}}/>
                                    </Item>
                                </Box>
                                <Box direction="row" width="100%">
                                    <Item ratio={1}>
                                        <div style={{paddingBottom: "5px", marginTop: "0"}}>End repeat:</div>
                                        <div className={"formField_container"} style={{display: "flex"}}>
                                            <RadioGroup valueExpr={"text"} value={that.state.repeatEndOptions}
                                                        onValueChanged={that.repeatEndOptionsValueOnChange}>
                                                <Item ratio={1} text={"no-end-date"} render={
                                                    (e) => that.repeatNoEndDateTemplate({e: e, thisComp: that})
                                                }/>
                                                <Item ratio={1} text={"end-by-date"} render={
                                                    (e) => that.repeatEndByDateTemplate({e: e, thisComp: that})
                                                }/>
                                                <Item ratio={1} text={"end-by-occurrence"} render={
                                                    (e) => that.repeatEndByOccurrenceTemplate({e: e, thisComp: that})
                                                }/>
                                            </RadioGroup>
                                        </div>
                                    </Item>
                                </Box>
                            </div>
                        </form>
                    </div>
                    <div style={{height: "100%"}}></div>
                    <div style={{textAlign: "right", paddingRight: "10px"}}>
                        <Button text={"OK"} className={"leopard-button"} onClick={that.buttonOkOnClick}
                                style={{marginRight: "10px"}}></Button>
                        <Button text={"Cancel"} className={"leopard-button"}
                                onClick={that.buttonCancelOnClick}></Button>
                    </div>
                </div>
            </Popup>
        )
    }
}

const RetrieveDataFromReducer = (state) => {
    return {state};
};
export default connect(RetrieveDataFromReducer, null)(LeopardRecurrenceEditorPopup);
